import { styled } from '@mui/system'

const TopAreaWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.common.white};
	margin-top: 10px;
	margin-bottom: 10px;

	${({ theme }) => theme.breakpoints.up('sm')} {
		margin-top: 100px;
	}

	.MuiContainer-root {
		gap: 2px;
	}
`

export { TopAreaWrapper }
