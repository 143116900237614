import { styled } from '@mui/system'

const MiddleTopWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleTop};
	max-width: var(--layout-max-width);
	display: flex;
	flex-direction: column;
	margin: auto;
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		padding: ${({ theme }) => theme.spacing(5.5, 17, 4)};
	}
	& .BHNewsletter {
		padding: ${({ theme }) => theme.spacing(4, 3, 0)};
		.MuiTypography-root {
			line-height: var(--layout-line-height-medium);
			font-size: ${({ theme }) => theme.typography.pxToRem(18)};
			font-family: ${({ theme }) => theme.typography.fontFamily};
			${({ theme }) => theme.breakpoints.up('md')} {
				font-weight: bold;
				font-size: ${({ theme }) => theme.typography.pxToRem(12)};
				line-height: var(--layout-line-height-small);
			}
		}
		.MuiInputBase-root {
			border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
			border-radius: 0;
		}
		.MuiInputBase-input {
			padding: ${({ theme }) => theme.spacing(2)};
			${({ theme }) => theme.breakpoints.up('md')} {
				padding: ${({ theme }) => theme.spacing(2, 3)};
			}
		}
		.MuiButtonBase-root {
			right: 0;
			top: 0;
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			padding: 0;
		}
	}
`

const NewsletterWrapper = styled('div')`
	max-width: 368px;
	order: 1;
	${({ theme }) => theme.breakpoints.up('md')} {
		order: 2;
		max-width: 255px;
	}
`

export { MiddleTopWrapper, NewsletterWrapper }
