import TopArea from './top-area'
import MiddleTopArea from './middle-top-area'
import MiddleBottomArea from './middle-bottom-area'
import BottomArea from './bottom-area'
import { Wrapper } from './footer.styled'
import { string, array, object, node } from 'prop-types'
import { cx } from '@emotion/css'
import RichContent from '../../../particles/rich-content'
import { useCmsBlock } from '@bluheadless/ui-logic/src/hooks/cms/useCmsBlock'
import dynamic from 'next/dynamic'

const BackTopButton = dynamic(() => import('@/components/ui/molecules/back-top-button/back-top-button'), { ssr: false })

const Footer = ({ className, topAreaChildComponent, newsletterProps, copyrightProps, menus = [] }) => {
	const { content: cmsBlockContent } = useCmsBlock({ identifier: 'footer-services' })
	// const { content: logoCmsBlockContent } = useCmsBlock({ identifier: 'footer-logo-scroll' })

	return (
		<Wrapper className={cx('Footer-root', className)}>
			<TopArea childComponent={topAreaChildComponent ?? <RichContent content={cmsBlockContent} />} />
			{/* <LogoArea>
				<RichContent content={logoCmsBlockContent} />
			</LogoArea> */}
			<MiddleTopArea menus={menus} newsletterProps={newsletterProps} />
			<MiddleBottomArea />
			<BottomArea copyrightProps={copyrightProps} />
			<BackTopButton />
		</Wrapper>
	)
}

Footer.propTypes = {
	/**
	 * Defines a classname for root node
	 */
	className: string,
	/**
	 * Defines topArea Content
	 */
	topAreaChildComponent: node,
	/**
	 * Defines newsletter properties
	 */
	newsletterProps: object,
	/**
	 * Defines the properties of copyright text
	 */

	copyrightProps: object,
	/**
	 * Defines the footer menus array
	 */
	menus: array,
}

export default Footer
